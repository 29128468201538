<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">搜索结果</div>
      <div class="topRight"></div>
    </div>
    <div class="conetnt_botm">
       <div class="contentBox" v-for="item in trends" :key="item.id">
              <div class="content_top">
                <div class="content_hear" @click="gotrends(item.userId)">
                  <img :src="item.userHeadImg">
                </div>
                <div class="content_name_school">
                  <div class="content_name">{{ item.userNickName }}</div>
                  <div class="content_school">{{ item.schoolName }}</div>
                </div>
                <div class="content_topBtn">
                  <img src="../../image/quanz/gz.png" alt="" v-if="item.userId !== thisUserId && item.isCollect === 0" @click="addUserFollow(item.userId)"/>
                  <img src="../../image/quanz/ygz.png" alt="" v-if="item.userId !== thisUserId && item.isCollect === 1"/>
                </div>
              </div>
              <div class="content_abstract" @click="goTrendsDetail(item.id)">
                {{ item.content }}
              </div>
              <div class="content_img">
                <img :src="item" alt="" v-for="item in JSON.parse(item.imgs)" :key="item"/>
              </div>
              <div class="content_footer">
                <div class="content_numBox">
                  <div class="content_leftBox">
                    <img src="../../image/quanz/fx.png" alt="" />分享
                  </div>
                  <div class="content_leftBox">
                    <img src="../../image/quanz/pl.png" alt="" />{{ item.commentNum }}
                  </div>
                  <div class="content_leftBox">
                    <img src="../../image/quanz/dz.png" alt="" />{{ item.likeNum }}
                  </div>
                </div>
                <div class="content_del">
                  <img src="../../image/quanz/del.png" alt="" />
                </div>
              </div>
            </div>
    </div>
  </div>
</template>

<script>
import {listTrends, addUserFollow} from '@/api/trends'
export default {
  data() {
    return {
      thisUserId: this.$store.state.user.id,
      search: {
        nickName: this.$route.query.value,
        pageNum: 1,
        pageSize: 10,
      },
       trends: [],
    };
  },
  mounted() {
    this.listTrends()
  },
  methods: {
    goBack() {
      this.$router.push({path:'/circleIndex'});
    },
     // 跳转详情
    goTrendsDetail(id) {
      this.$router.push({ path: "/trendsDetail", query: {id: id} });
    },
     listTrends() {
      listTrends(this.search).then(res => {
        console.log(res,'搜索结果');
        this.trends = this.trends.concat(res.data.data.items);
        if (this.trends.length >= res.data.data.total) {
          this.finished = true;
        } else {
          this.finished = false;
        }
      })
    },
     // 添加关注
    addUserFollow(followId) {
      addUserFollow(followId).then(res => {
        console.log("添加成功")
      })
    }
  },
};
</script>

<style scoped>
.setBox {
  background: #f6f8fa;
  min-height: 100vh;
}
.boxTop {
  width: 750px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight {
  width: 48px;
  height: 48px;
  margin-right: 32px;
}
.conetnt_botm {
  padding-bottom:80px;
}
.contentBox {
  width: 686px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 32px;
  overflow: hidden;
}
.content_top {
  display: flex;
  align-items: center;
  margin-top: 36px;
}
.content_hear  img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-left: 32px;
}
.content_name_school {
  width: 380px;
  margin-left: 32px;
}
.content_name {
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
}
.content_school {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 6px;
}

.content_topBtn img {
  width: 120px;
  height: 56px;
}
.content_abstract {
  width: 622px;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 32px;
  line-height: 48px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content_img {
  /* display: flex; */
  margin-left: 32px;
  margin-top: 32px;
}
.content_img img {
  width: 198px;
  height: 198px;
  border-radius: 8px 8px 8px 8px;
  margin-right: 12px;
}
.content_footer {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:32px;
}
.content_numBox {
  /* width: 400px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* margin-left: 32px; */
  margin-top: 12px;
}
.content_leftBox {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  display: flex;
  align-items: center;
}
.content_leftBox img {
  width: 48px;
  height: 48px;
  margin-right: 16px;
  margin-left: 32px;
}
.content_del img {
  width: 24px;
  height: 24px;
  margin-right: 32px;
  display: flex;
  margin-top: 12px;
}
</style>
